import React from "react"
import Header from "../components/header"
import Menue from "../components/menue"
import Timeline from "../components/timeline"
import Projects from "../components/projects"


const IndexPage = () => {
    return (
            <div>
              <Menue/>
              <Header/>
              <Timeline/>      
            </div>
    )
}

export default IndexPage