import React from "react"
import { makeStyles, Container, Box, Grid} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
 head: {
   marginTop: "100px",
   marginBottom: "100px"
 }

}))

export default function Projects() {
  const classes = useStyles()
  return(
  <Box>
    <Container maxWidth="lg">
    <Grid className={classes.head}>
      Projects
    </Grid>
    </Container>
  </Box>
  )
}