import React from "react"
import { makeStyles, Box} from "@material-ui/core"
import logo from "../../img/logo.svg"
// import github from "../../img/github.svg"
// import linkedin from "../../img/linkedin.svg"

const useStyles = makeStyles(theme => ({
  logo: {
    padding: "30px",
    height: "100px",
  },
  socialMedia: {
    float: "right",
    padding: "20px",
  },
  icons: {
    filter: 'invert(100%)',
    padding: "10px",
    height: "30px",
  }
}))

export default function Menue() {
  const classes = useStyles()
  return(
        <Box>
        <img className={classes.logo} src={logo} alt="logo"/>
        {/* <div className={classes.socialMedia}>
        <a href="https://github.com/edfii">
          <img className={classes.icons} src={github} alt="github"/>
        </a>
        <a href="https://www.linkedin.com/in/edith-f-20a086229/">
          <img className={classes.icons} src={linkedin} alt="linkedin"/>
        </a>
        </div> */}
        </Box>
  )
}