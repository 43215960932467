import React from "react"
import { makeStyles, Box, Grid, Hidden} from "@material-ui/core"
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

const useStyles = makeStyles(theme => ({
  display: {
    display: "block",
    padding: "20px",
    width: "80%",
    margin: "auto",
    marginTop: "-15%",
    boxShadow: "-10px 20px #00FFB3",
    border: "3px solid white",
    backgroundColor: "#ae3400",
    [theme.breakpoints.down('sm')]: {
      marginTop: "0px",
      width: "95%",
    },
  },
  list: {
    color: "#000000"
  },
  chip: {
    marginTop: "10px",
    marginRight: "10px",
    border: "3px solid white",
    display: "inline-block",
    padding: "5px",
  },
  timeline: {
    paddingBottom: "80px",
  }
}))

const data = [
  {
    label: 'Frontend Development',
    where: 'exxeta - Stuttgart',
    date: '08/2021 - now',
    description: '<ul><li>Frontend Development of an E-Commerce Project with React and TypeScript</li><li>Conception, design and development of a knowledgebase prototype. Development with Jekyll. Design of the website with Figma.</li><li>Participation on a web application for agile working. Implementation of individual components with Figma. Development of components with Angular.</li></ul>',
    items: [
      { label: 'Angular', },
      { label: 'React', },
      { label: 'Storybook', },
      { label: 'TypeScript', },
      { label: 'Jest', },
      { label: 'GitLab', },
      { label: 'AWS', },
      { label: 'Jekyll', },
      { label: 'Figma', },
      { label: 'Jira', },
      { label: 'Scrum', },
      { label: 'LeSS', },
    ]
  },
  {
    label: 'Computer Science and Media B.Sc',
    where: 'Hochschule der Medien - Stuttgart',
    date: '03/2018 - 09/2021',
    description: '<b>Bachelor Thesis:</b> Possibilities for using Web Components in design systems </br> ',
    items: [
    ]
  },
  {
    label: 'Immunhelden website',
    where: 'Student Project',
    date: 'winter term 2020/21',
    description: 'Development of the Immunhelden frontend website with React and Gatsby',
    items: [
      { label: 'React', },
      { label: 'HTML', },
      { label: 'CSS', },
      { label: 'Gatsby', },
      { label: 'Firebase', },
      { label: 'GitHub', },
    ]
  },
  {
    label: 'Digital Media Development',
    where: 'Student | Vector Informatik - Stuttgart',
    date: '11/2019 - 12/2020',
    description: '<ul> <li>Redesign of the Vector Knowledge Base with Adobe XD. Development of the snippets with HTML and CSS </li> <li> Relaunch of Vector Venture Capital website - conception, design and development </li> <li> Design and development of websites with HTML, CSS and JS </li> <li> technical support of the Coderskitchen WordPress Blog </li> <li> Adding and structuring content in the Vecor Digital Assets Management System </li></ul>',
    items: [
      { label: 'Adobe XD', },
      { label: 'HTML', },
      { label: 'CSS', },
      { label: 'ServiceNow', },
      { label: 'Wordpress', },
      { label: 'Bootstrap', },
      { label: 'Elementor', },
      { label: 'Google Analytics', },
    ]
  },
  {
    label: 'Hackathon „Neue Wege“',
    where: 'SWA - Augsburg',
    date: '11/2018',
    description: 'Development of the UI for a multimodal transport app',
    items: [
      { label: 'GitHub', },
      { label: 'Adobe XD', },
    ]
  },
  {
    label: 'digital and print media designer',
    where: 'Vocational training - Tac office Marketing / Carl-Hofer Schule - Karlsruhe',
    date: '09/2015 - 02/2018',
    description: '<b>Creation of print materials</b> <ul> <li> artwork production for print products </li> <li> Conceptual implementation of various advertising materials </ul> <br> <b>Creation of digital media</b> <ul> <li> Conception and development of screen designs </li> <li> Development of websites with HTML, CSS, JS, Drupal and Wordpress </li> </ul>',
    items: [
      { label: 'HTML', },
      { label: 'CSS', },
      { label: 'Bootstrap', },
      { label: 'Wordpress', },
      { label: 'Drupal', },
      { label: 'Google Analytics', },
      { label: 'Piwik', },
      { label: 'Power Point', },
      { label: 'Illustrator', },
      { label: 'Photoshop', },
      { label: 'InDesign', },
    ]
  },
  {
    label: 'Hackathon „Jugend Hackt“',
    where: 'Open Knowledge Foundation - Berlin',
    date: '09/2014',
    description: 'We developed a map with open data to show Wildlife accidents',
    items: [
      { label: 'GitHub', },
      { label: 'HTML', },
      { label: 'CSS', },
    ]
  },
]


export default function Card() {
  const classes = useStyles()
  return(
  <Box className={classes.timeline}>
  <Hidden smDown>
   <Timeline align="alternate">
    {data.map(( card, cardIndex ) => (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
        <Grid className={classes.display}>
          <h2>{card.label}</h2>
          <h3>{card.where}</h3>
          <p className={classes.date}>{card.date}</p>
          <p dangerouslySetInnerHTML={{__html: card.description}}></p>

          {card.items.map(( chip, chipIndex ) => (
            <Box className={classes.chip} > {chip.label} </Box>
          ))}
        </Grid>
        </TimelineContent>
      </TimelineItem>
     ))}
    </Timeline>  
    </Hidden>
    <Hidden mdUp>
   <Timeline align="default">
    {data.map(( card, cardIndex ) => (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
        <Grid className={classes.display}>
          <h2>{card.label}</h2>
          <h3>{card.where}</h3>
          <p className={classes.date}>{card.date}</p>
          <p dangerouslySetInnerHTML={{__html: card.description}}></p>

          {card.items.map(( chip, chipIndex ) => (
            <Box className={classes.chip} > {chip.label} </Box>
          ))}
        </Grid>
        </TimelineContent>
      </TimelineItem>
     ))}
    </Timeline>  
    </Hidden>
  </Box>
  )
}