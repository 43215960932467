import React from "react"
import { makeStyles, Container, Box, Grid} from "@material-ui/core"
import portrait from "../../img/portfolio.png"
import welcome from "../../img/welcome.svg"

const useStyles = makeStyles(theme => ({
  portrait: {
    zIndex: "1",
    position: "relative",
    boxShadow: "-15px -15px #00FFB3",
    border: "4px solid white",
    [theme.breakpoints.down('sm')]: {
      width: "300px",
      height: "200px",
      objectFit: "cover",
      objectPosition: "center bottom"
    },
  },
  boxleft: {
    display: "flex",
    justifyContent: "center",
  },
  boxright: {
    marginTop: "50px",
  },

}))

export default function Header() {
  const classes = useStyles()
  return(
  <Box className={classes.background}>
    <Container maxWidth="lg">
    <Grid container>
      <Grid container item xs={12} md={6} className={classes.boxleft}>
        <img src={portrait} className={classes.portrait} alt="portrait"/>
      </Grid>
      <Grid container item xs={12} md={6} className={classes.boxright}>
        <Box className={classes.introtext}>
        <img src={welcome} alt="welcome"/>
        <h2>I'm Edith. A Frontend Developer <br/> with a focus on UI/UX-Design</h2>
        </Box>
      </Grid>
    </Grid>
    </Container>
  </Box>
  )
}